import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './button.module.css';

const Button = ({
    children,
    to,
    onClick,
    variant = 'primary',
    size = 'medium',
    className = '',
    type = 'button',
    icon,
    iconSize = "lg",
    selected = false
}) => {
    const variantClass = selected ? 'selected' : variant;
    const buttonClass = `${styles.button} ${styles[variantClass]} ${styles[size]} ${className}`;
    const content = icon ? <FontAwesomeIcon icon={icon} size={iconSize} /> : children;

    if (to) {
        return (
            <Link to={to} className={buttonClass}>
                {content}
            </Link>
        );
    }

    return (
        <button
            type={type}
            onClick={onClick}
            className={buttonClass}
        >
            {content}
        </button>
    );
};

export default Button;
