import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n/i18n';
import { TranslationErrorBoundary } from './components/TranslationErrorBoundary';
import { LanguageLoader } from './components/LanguageLoader';
import { TranslationProvider } from './contexts/TranslationContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <TranslationErrorBoundary>
            <TranslationProvider>
                <LanguageLoader>
                    <App />
                </LanguageLoader>
            </TranslationProvider>
        </TranslationErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
