import React from 'react';
import styles from './card.module.css';

const Card = ({ 
    children,
    className = '',
    variant = 'default', // 'default', 'hover', 'interactive'
    onClick,
    margin,    // Can be a string like '1rem' or '20px'
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
}) => {
    // Only include margins that are actually provided
    const style = {
        ...(margin && { margin }),
        ...(marginTop && { marginTop }),
        ...(marginRight && { marginRight }),
        ...(marginBottom && { marginBottom }),
        ...(marginLeft && { marginLeft }),
    };

    // Log the style object to verify it's being created correctly
    console.log('Card style:', style);

    return (
        <div 
            className={`
                ${styles.card} 
                ${styles[variant]}
                ${onClick ? styles.clickable : ''} 
                ${className}
            `}
            style={style}
            onClick={onClick}
            role={onClick ? 'button' : undefined}
            tabIndex={onClick ? 0 : undefined}
        >
            {children}
        </div>
    );
};

export default Card;
