import React, { useState, useEffect } from 'react';
import styles from './image-slideshow.module.css';

const ImageSlideshow = ({ 
    images, // Array of { src, alt } objects
    interval = 3000, // Time between slides in ms
    className = '', // Optional additional classes
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => 
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, interval);

        return () => clearInterval(timer);
    }, [images.length, interval]);

    return (
        <div
            role="region"
            aria-label="Image Slideshow"
            className={`${styles.slideshow} ${className}`}
        >
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={`${styles.slideshowImage} ${index === currentIndex ? styles.active : ''}`}
                />
            ))}
            <div className={styles.dots}>
                {images.map((_, index) => (
                    <button
                        key={index}
                        className={`${styles.dot} ${index === currentIndex ? styles.activeDot : ''}`}
                        onClick={() => setCurrentIndex(index)}
                        aria-label={`Go to slide ${index + 1}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageSlideshow;
