import { Link } from 'react-router-dom';
import { Card } from '../../../../components/ui';
import styles from './blog-card.module.css';

const BlogCard = ({
    slug,
    title,
    content,
    metadata,
    media
}) => (
    <Link to={`/blogs/${slug}`} className={styles.cardLink}>
        <Card className={styles.blogCard} variant="interactive">
            <div className={styles.imageWrapper}>
                <img src={media.thumbnailImage} alt={media.altText} />
            </div>
            <div className={styles.content}>
                <span className={styles.date}>
                    {new Date(`${metadata.publishDate}T00:00`).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    })}
                </span>
                <h3>{title}</h3>
                <div className={styles.description}>
                    {content.description.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                </div>
                <div className={styles.metadata}>
                    <span>{metadata.readTime} read</span>
                    {/* {metadata.tags.slice(0, 2).map(tag => (
                        <span key={tag} className={styles.tag}>{tag}</span>
                    ))} */}
                </div>
            </div>
        </Card>
    </Link>
);

export default BlogCard;
