import { useTranslation } from 'react-i18next';

export const LanguageLoader = ({ children }) => {
  const { i18n } = useTranslation();
  
  if (!i18n.isInitialized) {
    return <div>Loading...</div>;
  }
  
  return children;
};
