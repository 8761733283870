import { createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TranslationContext = createContext();

export function TranslationProvider({ children }) {
    const { t, i18n } = useTranslation();
  
    useEffect(() => {
        // Set the lang attribute whenever language changes
        document.documentElement.lang = i18n.language;
    }, [i18n.language]);

    const changeLanguage = async (lang) => {
        try {
            await i18n.changeLanguage(lang);
            document.documentElement.lang = lang;
        } catch (error) {
            console.error('Language change failed:', error);
        }
    };
  
    return (
        <TranslationContext.Provider value={{ t, i18n, changeLanguage }}>
            {children}
        </TranslationContext.Provider>
    );
}
