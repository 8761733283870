import React from 'react';

export class TranslationErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div>Error loading translations</div>;
    }

    return this.props.children;
  }
}
