// Replace the Vite glob import with Webpack's require.context
const importAll = (r) => {
    const images = {};
    r.keys().forEach((key) => {
        // Extract the filename with path but without extension
        // e.g., './weekend-ai-for-kids/WeekendAI01.jpg' becomes 'weekend-ai-for-kids/WeekendAI01'
        const imageName = key
            .replace('./', '')  // Remove leading ./
            .replace(/\.(png|jpe?g|webp)$/, '');  // Remove extension
        images[imageName] = r(key);
    });
    return images;
};

// Import all images from the resources directory and its subdirectories
const blogImages = importAll(require.context('../resources', true, /\.(png|jpe?g|webp)$/));

// Helper function to get image path
const getImage = (imageName) => {
    return blogImages[imageName] || null;
};

// This structure mirrors how documents would look in MongoDB
// Status: published, draft, archived,
export const blogPosts = [
    {
        _id: 'weekend-ai-for-kids',
        slug: 'weekend-ai-for-kids',
        title: "Weekend AI for Kids",
        subtitle: "",
        content: {
            description: [
                "Throughout the summer of 2024, we launched a series of \"Weekend AI for Kids\" workshops that combined English learning with hands-on AI and technology applications using a project-based approach. Students developed real-world skills such as creativity, teamwork, and problem-solving, while working on a large project throughout the course.",
            ],
            fullText: `
                <p>Throughout the summer of 2024, we launched a series of <b>"Weekend AI for Kids"</b> workshops that combined <b>English learning with hands-on AI and technology applications</b> using a <b>project-based approach</b>. Students developed <b>real-world skills</b> such as <b>creativity, teamwork, and problem-solving</b>, while working on a <b>large project</b> throughout the course.</p>
                <p>In the <b>Creative Arts with AI</b> course, students <b>designed their own heroes and creatures, built fantasy worlds, and crafted illustrated fairy tale books.</b></p>
                <p>In the <b>Introduction to Programming</b> course, students <b>explored Scratch visual programming, learned animation and coding fundamentals, and developed their own interactive adventures.</b></p>
                <p>🔹 <b>Join us in bringing AI-powered, project-based learning to more young minds!</b></p>
                <p>2024年夏天，我們推出了一系列 <b>「兒童AI週末工作坊」</b>，將<b>英語學習與AI及科技應用</b> 相結合，並採用 <b>專案式學習（PBL）</b>，讓孩子在實作中學習。孩子們在課程中培養了 <b>創造力、團隊合作與問題解決能力</b>，並在整個學習過程中完成了一個 <b>大型專案</b>。</p>
                <p>在 <b>「AI創意藝術」</b> 課程中，孩子們 <b>設計自己的英雄與奇幻生物，創造專屬的故事世界，並製作精美的插畫童話書。</b></p>
                <p>在 <b>「程式設計入門」</b> 課程中，孩子們 <b>學習Scratch視覺化編程，掌握動畫與程式設計概念，並打造互動式冒險故事。</b></p>
                <p>🔹 <b>讓我們一起將AI驅動的專案式學習帶入更多孩子的成長旅程！</b></p>
                <p><a href="https://www.facebook.com/EverydayAI.TW">https://www.facebook.com/EverydayAI.TW</a></p>
            `,
        },
        metadata: {
            author: "John Liaw",
            publishDate: "2024-07-14",
            lastModified: "2024-07-14",
            readTime: "<1 min",
            tags: ["AIforKids", "STEAMlearning", "EnglishThroughInnovation", "ProjectBasedLearning", "FutureSkills", "兒童AI", "STEAM教育", "創新英語學習", "專案式學習", "未來技能"]
        },
        media: {
            featuredImage: getImage('weekend-ai-for-kids/WeekendAI01'),
            thumbnailImage: getImage('weekend-ai-for-kids/WeekendAI01'),
            altText: "Weekend AI for Kids",
            slideshow: [
                {
                    src: getImage('weekend-ai-for-kids/WeekendAI01'),
                    alt: "Students smiling at the camera"
                },
                {
                    src: getImage('weekend-ai-for-kids/WeekendAI02'),
                    alt: "Creative arts with AI workshop"
                },
                {
                    src: getImage('weekend-ai-for-kids/WeekendAI03'),
                    alt: "Student taking a break"
                },
                {
                    src: getImage('weekend-ai-for-kids/WeekendAI04'),
                    alt: "Student looking at their project"
                },
                {
                    src: getImage('weekend-ai-for-kids/WeekendAI05'),
                    alt: "Student working with Scratch"
                }
            ]
        },
        seo: {
            metaTitle: "兒童AI週末工作坊 | Kinetic101",
            metaDescription: "兒童AI週末工作坊",
            keywords: ["兒童AI週末工作坊", "AI創意藝術", "程式設計入門"]
        },
        status: "published",
        featured: true
    },
    {
        _id: 'english-ai-winter-camp-for-kids',
        slug: 'english-ai-winter-camp-for-kids',
        title: "English+AI Winter Camp for Kids",
        subtitle: "",
        content: {
            description: [
                "At our \"English+AI Winter Camp for Kids\", we combined English learning, Project-Based Learning (PBL), and AI into engaging daily themes such as art, cooking, music, science, and geography.",
            ],
            fullText: `
                <p>At our <b>"English+AI Winter Camp for Kids"</b>, we combined <b>English learning, Project-Based Learning (PBL), and AI</b> into engaging daily themes such as <b>art, cooking, music, science, and geography.</b></p>
                <p>Students participated in <b>hands-on projects</b> like building bridges and marshmallow towers, writing songs, baking cookies, inventing new eating tools, playing chess, and using AI to transform sketches into digital masterpieces. Through these activities, children developed <b>creativity, problem-solving skills, teamwork, and tech fluency</b>, preparing them for the challenges of the future.</p>
                <p>在我們的<b>「English+AI冬令營」</b>，我們將<b>英語學習、專案式學習（PBL）與人工智慧（AI）</b>結合到每日主題中，包括<b>藝術、烹飪、音樂、科學與地理</b>，讓孩子在動手實作中學習與成長。</p>
                <p>孩子們透過一系列互<b>動式專案</b>，如<b>搭建橋樑與棉花糖塔、創作歌曲、烘焙餅乾、發明創新餐具、下西洋棋，以及運用AI將手繪草圖轉換為數位藝術</b>，在玩樂中培養創<b>造力、問題解決能力、團隊合作與科技素養</b>，為未來挑戰做好準備！</p>
                <p><a href="https://www.facebook.com/EverydayAI.TW">https://www.facebook.com/EverydayAI.TW</a></p>
            `,
        },
        metadata: {
            author: "John Liaw",
            publishDate: "2024-01-29",
            lastModified: "2024-01-29",
            readTime: "<1 min",
            tags: ["EnglishLearning", "AIinEducation", "STEAM", "ProjectBasedLearning", "FutureSkills", "EducationInnovation", "英語學習", "AI教育", "STEAM", "專案式學習", "未來技能", "教育創新"]
        },
        media: {
            featuredImage: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids01'),
            thumbnailImage: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids01'),
            altText: "English+AI Winter Camp for Kids",
            slideshow: [
                {
                    src: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids01'),
                    alt: "Image 1"
                },
                {
                    src: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids02'),
                    alt: "Image 2"
                },
                {
                    src: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids03'),
                    alt: "Image 3"
                },
                {
                    src: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids04'),
                    alt: "Image 4"
                },
                {
                    src: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids05'),
                    alt: "Image 5"
                },
                {
                    src: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids06'),
                    alt: "Image 6"
                },
                {
                    src: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids07'),
                    alt: "Image 7"
                },
                {
                    src: getImage('english-ai-winter-camp-for-kids/English+AI Winter Camp for Kids08'),
                    alt: "Image 8"
                }
            ]
        },
        seo: {
            metaTitle: "English+AI冬令營 | Kinetic101",
            metaDescription: "English+AI冬令營",
            keywords: ["English+AI冬令營", "英語學習", "AI教育", "STEAM", "專案式學習", "未來技能", "教育創新"]
        },
        status: "published",
        featured: true
    },
    {
        _id: 'english-ai-workshop-for-kids',
        slug: 'english-ai-workshop-for-kids',
        title: "English+AI Workshop for Kids",
        subtitle: "",
        content: {
            description: [
                "In January 2024, we launched our first pilot program, \"English + AI Workshop for Kids.\" This workshop combined English learning with AI exploration, giving students an early introduction to practical AI applications. Through engaging activities, children learned English through AI-related concepts while developing their creativity in storytelling and image creation.",
            ],
            fullText: `
                <p>In January 2024, we launched our first pilot program, <b>"English+AI Workshop for Kids"</b>. This workshop <b>combined English learning with AI exploration</b>, giving students an early introduction to <b>practical AI applications</b>. Through engaging activities, children <b>learned English through AI-related concepts</b> while developing their creativity in <b>storytelling and image creation</b>.</p>
                <p>2024年1月，我們成功推出首場試點課程！我們的**<b>「英語 + AI 兒童工作坊」</b>巧妙結合<b>英語學習與AI探索**</b>，讓孩子從小接觸<b>AI的實際應用</b>。透過有趣的互動活動，孩子們用AI語言學英語，並透過<b>故事創作與圖像生成</b>發揮創意！</p>
                <p><a href="https://www.facebook.com/EverydayAI.TW">https://www.facebook.com/EverydayAI.TW</a></p>
            `,
        },
        metadata: {
            author: "John Liaw",
            publishDate: "2024-01-14",
            lastModified: "2024-01-14",
            readTime: "<1 min",
            tags: ["AIinEducation", "EnglishLearning", "FutureSkills", "CreativeThinking", "TaiwanEducation", "AI教育", "英語學習", "未來技能", "創意思維", "台灣教育"]
        },
        media: {
            featuredImage: getImage('english-ai-workshop-for-kids/English+AI Workshop for Kids01'),
            thumbnailImage: getImage('english-ai-workshop-for-kids/English+AI Workshop for Kids01'),
            altText: "English+AI Workshop for Kids",
            slideshow: [
                {
                    src: getImage('english-ai-workshop-for-kids/English+AI Workshop for Kids01'),
                    alt: "Image 1"
                },
                {
                    src: getImage('english-ai-workshop-for-kids/English+AI Workshop for Kids02'),
                    alt: "Image 2"
                },
                {
                    src: getImage('english-ai-workshop-for-kids/English+AI Workshop for Kids03'),
                    alt: "Image 3"
                },
                {
                    src: getImage('english-ai-workshop-for-kids/English+AI Workshop for Kids04'),
                    alt: "Image 4"
                }
            ]
        },
        seo: {
            metaTitle: "English+AI工作坊 | Kinetic101",
            metaDescription: "English+AI工作坊",
            keywords: ["English+AI工作坊", "英語學習", "AI教育", "創意思維", "台灣教育"]
        },
        status: "published",
        featured: true
    },
    {
        _id: 'undersea-operation-ivermason',
        slug: 'undersea-operation-ivermason',
        title: "新北市2019年資訊科技教育成果展(海底行動 伊弗梅遜)",
        subtitle: "New Taipei City 2019 Information Technology Education Achievement Exhibition (Undersea Operation Ivermason)",
        content: {
            description: [
                "在2019年新北市資訊科技教育成果展中，Kinetic 101 partner Dean以**「海底行動˙伊弗梅遜」為主題，開發大型AR海底彩繪系統**，作為成果展的開幕活動，透過擴增實境技術轉化為動態場景，沉浸式體驗智慧學習。",
            ],
            fullText: `
                <p><b>AR互動學習新體驗——2019年新北市資訊科技教育成果展</b></p>
                <p>在2019年新北市資訊科技教育成果展中，Kinetic 101 partner Dean以**「海底行動˙伊弗梅遜」為主題，開發大型AR海底彩繪系統**，作為成果展的開幕活動，透過擴增實境技術轉化為動態場景，沉浸式體驗智慧學習。</p>
                <p>活動中也邀請了大師開講：邀請專家分享資訊科技在教育中的應用與發展趨勢。這次展覽不僅展示了資訊科技如何融入教育，也推動了創新教學模式與智慧學習環境的發展。</p>
                <p>如今，Dean將這些經驗帶入Kinetic101，持續探索AR、AI與物聯網技術在教育與學習領域的應用，推動更沉浸式、互動化的學習體驗，讓科技真正成為提升教育品質的關鍵力量。</p>
                <p><b>AR interactive learning new experience - 2019 New Taipei City Information Technology Education Achievement Exhibition</b></p>
                <p>At the 2019 New Taipei City Information Technology Education Exhibition, Kinetic 101 partner Dean developed a large-scale AR underwater painting system with the theme of "Undersea Operation: Ivermason" as the opening event of the exhibition. Through augmented reality technology, it was transformed into a dynamic scene, providing an immersive experience of smart learning.</p>
                <p>Masters were also invited to give lectures at the event: experts were invited to share the application and development trends of information technology in education. This exhibition not only demonstrated how information technology is integrated into education, but also promoted the development of innovative teaching models and smart learning environments.</p>
                <p>Today, Dean brings these experiences to Kinetic101, continuing to explore the application of AR, AI and IoT technologies in education and learning, promoting a more immersive and interactive learning experience, and making technology truly a key force in improving the quality of education.</p>
            `,
        },
        metadata: {
            author: "Dean Ye",
            publishDate: "2019-10-18",
            lastModified: "2019-10-18",
            readTime: "<1 min",
            tags: ["新北市資訊科技教育成果展", "海底行動", "伊弗梅遜"]
        },
        media: {
            featuredImage: getImage('undersea-operation-ivermason/case2-1'),
            thumbnailImage: getImage('undersea-operation-ivermason/case2-1'),
            altText: "New Taipei City Programming Experience Education Center",
            slideshow: [
                {
                    src: getImage('undersea-operation-ivermason/case2-1'),
                    alt: "Image 1"
                },
                {
                    src: getImage('undersea-operation-ivermason/case2-2'),
                    alt: "Image 2"
                },
                {
                    src: getImage('undersea-operation-ivermason/case2-3'),
                    alt: "Image 3"
                },
                {
                    src: getImage('undersea-operation-ivermason/case2-4'),
                    alt: "Image 4"
                }
            ]
        },
        seo: {
            metaTitle: "新北市2019年資訊科技教育成果展(海底行動 伊弗梅遜) | Kinetic101",
            metaDescription: "新北市2019年資訊科技教育成果展(海底行動 伊弗梅遜)",
            keywords: ["新北市2019年資訊科技教育成果展", "海底行動", "伊弗梅遜"]
        },
        status: "published",
        featured: false
    },
    {
        _id: 'new-taipei-childrens-art-and-technology-month-exhibition',
        slug: 'new-taipei-childrens-art-and-technology-month-exhibition',
        title: "新北市兒童藝術科技月展",
        subtitle: "New Taipei City Children's Art and Technology Monthly Exhibition",
        content: {
            description: [
                "在2018年新北市藝術科技月活動中，Kinetic 101 partner Dean推出「藝術結合科技」的彩繪互動遊戲，運用TeamLamp的2D轉3D技術，讓孩子的手繪作品轉換為3D影像，進入外星人場景，並能與畫面中的自己所創作彩繪作品互動。",
            ],
            fullText: `
                <p><b>藝術結合科技——2018年新北市藝術科技月互動體驗</b></p>
                <p>在2018年新北市藝術科技月活動中，Kinetic 101 partner Dean推出「藝術結合科技」的彩繪互動遊戲，運用TeamLamp的2D轉3D技術，讓孩子的手繪作品轉換為3D影像，進入外星人場景，並能與畫面中的自己所創作彩繪作品互動。</p>
                <p>此外，孩子們還能利用彩繪作品合作對抗大魔王，透過互動遊戲提升團隊合作與創意表達能力。活動最後，每位參與者都還能將自己的手繪作品帶回家，實現科技與藝術的完美結合，讓學習充滿樂趣與創意。</p>
                <p>如今，Dean將這份科技與創新教育的經驗與精神帶入Kinetic101，致力於發展智慧學習與沉浸式互動科技，讓創新應用不僅停留在活動體驗，而能更廣泛地推動未來教育與數位內容的發展。</p>
                <p><b>Art combined with technology - 2018 New Taipei City Art and Technology Month interactive experience</b></p>
                <p>During the 2018 New Taipei City Art and Technology Month event, Kinetic 101 partner Dean launched an interactive painting game called "Art Combined with Technology". Using TeamLamp's 2D to 3D technology, children's hand-painted works can be converted into 3D images, entering an alien scene and interacting with their own painted works on the screen.</p>
                <p>In addition, children can use their painted works to work together to fight against the big devil, and improve their teamwork and creative expression skills through interactive games. At the end of the event, each participant can take his or her hand-painted work home, achieving a perfect combination of technology and art, making learning full of fun and creativity.</p>
                <p>Today, Dean has brought this experience and spirit of technology and innovative education into Kinetic101, dedicated to developing smart learning and immersive interactive technology, so that innovative applications will not only stay in the activity experience, but can more broadly promote the development of future education and digital content.</p>
            `,
        },
        metadata: {
            author: "Dean Ye",
            publishDate: "2018-08-03",
            lastModified: "2018-08-03",
            readTime: "<1 min",
            tags: ["新北市兒童藝術節"]
        },
        media: {
            featuredImage: getImage('new-taipei-childrens-art-and-technology-month-exhibition/case3-1'),
            thumbnailImage: getImage('new-taipei-childrens-art-and-technology-month-exhibition/case3-1'),
            altText: "2018 New Taipei City Art and Technology Month Interactive Experience",
            slideshow: [
                {
                    src: getImage('new-taipei-childrens-art-and-technology-month-exhibition/case3-1'),
                    alt: "Image 1"
                },
                {
                    src: getImage('new-taipei-childrens-art-and-technology-month-exhibition/case3-2'),
                    alt: "Image 2"
                },
                {
                    src: getImage('new-taipei-childrens-art-and-technology-month-exhibition/case3-3'),
                    alt: "Image 3"
                },
                {
                    src: getImage('new-taipei-childrens-art-and-technology-month-exhibition/case3-4'),
                    alt: "Image 4"
                }
            ]
        },
        seo: {
            metaTitle: "新北市兒童藝術科技月展 | Kinetic101",
            metaDescription: "新北市兒童藝術科技月展",
            keywords: ["新北市兒童藝術科技月展", "藝術結合科技", "互動遊戲"]
        },
        status: "published",
        featured: false
    },
    {
        _id: 'blog-5',
        slug: 'blog-5',
        title: "新北市程式體驗教育中心",
        subtitle: "New Taipei City Programming Experience Education Center",
        content: {
            description: [
                "2017年，Kinetic 101 partner Dean在新北市立青山國中小推動教育改革，將空教室改建為雲端物聯網教室，結合創客概念與多媒體科技，建置IR融合實境系統、Acer機器人程式系統與環控系統，提升學習互動性，促進學生創新與實踐。學生可錄製作品影片、連線遠端課程，並在開放式層架展示成果，強調實作與自主學習。該計畫獲得新北市長、教育局長、Acer董事長及王如杏校長的高度肯定，成為智慧教育的重要案例。",
            ],
            fullText: `
                <p><b>從智慧教室到Kinetic101的教育創新</b></p>
                <p>2017年，Kinetic 101 partner Dean在新北市立青山國中小推動教育改革，將空教室改建為雲端物聯網教室，結合創客概念與多媒體科技，建置IR融合實境系統、Acer機器人程式系統與環控系統，提升學習互動性，促進學生創新與實踐。學生可錄製作品影片、連線遠端課程，並在開放式層架展示成果，強調實作與自主學習。該計畫獲得新北市長、教育局長、Acer董事長及王如杏校長的高度肯定，成為智慧教育的重要案例。</p>
                <p>這段經歷奠定了Dean在教育科技領域的專業基礎，也影響了他在Kinetic101的發展方向。如今，他將當年打造智慧學習環境的經驗帶入Kinetic101，致力於創新科技應用，推動更智慧化的學習模式，拓展教育科技的可能性。</p>
                <p><b>Educational innovation from smart classroom to Kinetic101</b></p>
                <p>In 2017, Kinetic 101 partner Dean promoted educational reform at New Taipei City Qingshan Elementary and Middle School, converting empty classrooms into cloud-based IoT classrooms. Combining maker concepts with multimedia technology, he built an IR fusion reality system, Acer robot programming system, and environmental control system to enhance learning interactivity and promote student innovation and practice. Students can record videos of their work, connect to remote courses, and display their results on open shelves, emphasizing practical and independent learning. The project has been highly recognized by the New Taipei City Mayor, the Education Bureau Director, the Acer Chairman, and Principal Wang Ruxing, and has become an important case of smart education.</p>
                <p>This experience laid the foundation for Dean's professional expertise in the field of educational technology and also influenced the direction of his development at Kinetic101. Today, he brings his experience in creating a smart learning environment to Kinetic101, and is committed to innovating technology applications, promoting smarter learning models, and expanding the possibilities of educational technology.</p>
            `,
        },
        metadata: {
            author: "Dean Ye",
            publishDate: "2017-06-26",
            lastModified: "2017-06-26",
            readTime: "<1 min",
            tags: ["新北市青山國中小", "新北市程式體驗教育中心", "ACER"]
        },
        media: {
            featuredImage: getImage('new-taipei-programming-experience-education-center/case1-1'),
            thumbnailImage: getImage('new-taipei-programming-experience-education-center/case1-1'),
            altText: "New Taipei City Programming Experience Education Center",
            slideshow: [
                {
                    src: getImage('new-taipei-programming-experience-education-center/case1-1'),
                    alt: "Image 1"
                },
                {
                    src: getImage('new-taipei-programming-experience-education-center/case1-2'),
                    alt: "Image 2"
                },
                {
                    src: getImage('new-taipei-programming-experience-education-center/case1-3'),
                    alt: "Image 3"
                },
                {
                    src: getImage('new-taipei-programming-experience-education-center/case1-4'),
                    alt: "Image 4"
                }
            ]
        },
        seo: {
            metaTitle: "新北市程式體驗教育中心 | Kinetic101",
            metaDescription: "新北市程式體驗教育中心",
            keywords: ["新北市程式體驗教育中心", "新北市青山國中小", "ACER"]
        },
        status: "published",
        featured: false
    },
];

// Helper functions to get different sets of blogs
export const blogPostSets = {
    FEATURED: blogPosts.filter(post => post.featured),
    PUBLISHED: blogPosts.filter(post => post.status === 'published'),
    ALL: blogPosts
};

// Helper function to get a blog by slug
export const getBlogBySlug = (slug) =>
    blogPosts.find(post => post.slug === slug);

// Helper function to get blogs by tag
export const getBlogsByTag = (tag) =>
    blogPosts.filter(post => post.metadata.tags.includes(tag));
