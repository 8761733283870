import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ROUTES } from './routes/config';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Styles
import './styles/colors.css';

function App() {
    const { i18n } = useTranslation();
    const HomeComponent = ROUTES.MAIN[0].component;

    return (
        <>
            <Helmet>
                <html lang={i18n.language} />
                <meta name="language" content={i18n.language} />
            </Helmet>
            <Router>
                <Suspense fallback={<div>Loading...</div>}>
                    <div>
                        <Routes>
                            {/* Main Routes */}
                            {ROUTES.MAIN.map(({ path, component: Component }) => (
                                <Route
                                    key={path}
                                    path={path}
                                    element={<Component />}
                                />
                            ))}

                        {/* Blogs Routes */}
                        <Route path={ROUTES.BLOGS.ROOT}>
                            <Route index element={<HomeComponent />} />
                            {ROUTES.BLOGS.ROUTES.map(({ path, component: Component }) => (
                                <Route
                                    key={path}
                                    path={`:slug`}
                                    element={<Component />}
                                />
                            ))}
                        </Route>

                            {/* Tools Routes */}
                            <Route path={ROUTES.TOOLS.ROOT}>
                                <Route index element={<HomeComponent />} />
                                {ROUTES.TOOLS.ROUTES.map(({ path, component: Component }) => (
                                    <Route
                                        key={path}
                                        path={path}
                                        element={<Component />}
                                    />
                                ))}
                            </Route>

                            {/* Catch all route */}
                            <Route path="*" element={<HomeComponent />} />
                        </Routes>
                    </div>
                </Suspense>
            </Router>
        </>
    );
}

export default App;
