import { Link } from 'react-router-dom';
import { Card, ImageSlideshow } from '../../../../components/ui';
import styles from './blog-page.module.css';

const BlogPage = ({
    title,
    subtitle,
    date,
    image,
    slideshow,
    tags,
    content,
    backLink = '/blog'
}) => {
    return (
        <div className={styles.blogPost}>
            <div className={styles.header}>
                <Link to={backLink} className={styles.backButton}>
                    ← Back
                </Link>
                <div className={styles.metadata}>
                    <div className={styles.date}>{date}</div>
                </div>
                <h1>{title}</h1>
                {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
            </div>

            <Card>
                <div className={styles.grid}>
                    <div className={styles.imageContainer}>
                        {slideshow ? (
                            <ImageSlideshow
                                images={slideshow}
                                interval={3000}
                                className={styles.slideshow}
                            />
                        ) : (
                            image && <img src={image} alt={title} />
                        )}
                    </div>
                    <div className={styles.textContent}>
                        {content}
                    </div>
                </div>
            </Card>
            {tags && tags.length > 0 && (
                <div className={styles.tags}>
                    {tags.map(tag => (
                        <span key={tag} className={styles.tag}>
                            {tag}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default BlogPage;
