import React from 'react';
import BlogCard from '../blog-card';
import styles from './blog-grid.module.css';

const BlogGrid = ({ columns = 3, items }) => {
    return (
        <div className={`${styles.grid} ${styles[`columns${columns}`]}`}>
            {items.map((item) => (
                <BlogCard
                    key={item.id}
                    {...item}
                />
            ))}
        </div>
    );
};

export default BlogGrid;
